import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import { Button } from "@fluentui/react-components";
import { TiAttachment } from "react-icons/ti";
import { MdOutlineMailOutline } from "react-icons/md";
import { fetchLanguages } from "../../../../../Shared/Utilities/api-helper/languages";
import { Select } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { translateMail } from "../../../../../Shared/Utilities/api-helper/translate-email";
import Loader from "../../../../../Shared/components/loader";
import { TranslationContext } from "../../../../..";
const EmailTranslation = () => {
  const navigate = useNavigate();
  const [languages, setLanguages] = useState({});
  const [loading, setLoading] = useState(false);
  const language = useContext(TranslationContext);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const location = useLocation();
  const [translatedEmail, setTranslatedEmail] = useState(location.state?.translatedContent);
  const [emailBodyContent, setEmailBodyContent] = useState(location.state?.emailContent);

  // Fetch languages on component mount
  useEffect(() => {
    fetchLanguages().then((res) => {
      console.log(res);
      setLanguages(res); // Save the fetched language object
    });
  }, []);

  // Handle selection change
  const handleChange = async (event) => {
    const selectedLanguageValue = event.target.value;
    setSelectedLanguage(selectedLanguageValue);
    console.log(selectedLanguageValue); // Log the selected value
    setLoading(true); // Set loading to true before the async operation starts
    try {
      const response = await translateMail(selectedLanguageValue, emailBodyContent);
      console.log(response); // Log the translation result
      setTranslatedEmail(response.answer[0]); // Set the translated email
    } catch (error) {
      console.error("Error translating email:", error); // Log the error
    } finally {
      setLoading(false); // Ensure loading is false after the async operation, regardless of success or failure
    }
  };

  const gotoAttachmentSummary = () => {
    navigate("/AttachmentSummary");
  };
  const gotoEmailSummary = () => {
    navigate("/EmailSummary");
  };

  return (
    <div className="container">
      {loading && <Loader />}
      <h2>Summaries</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "30px",
        }}
      >
        <Button
          icon={<TiAttachment />}
          onClick={gotoAttachmentSummary}
          style={{
            backgroundColor: "#1B6EC3",
            clipPath: "polygon(0px 0px, 100% 0px, 100% 70%, 92% 100%, 0px 100%)",
            height: "40px",
            color: "white",
          }}
        >
          Attachment
        </Button>

        <Button
          icon={<MdOutlineMailOutline />}
          onClick={gotoEmailSummary}
          style={{
            backgroundColor: "#1B6EC3",
            clipPath: "polygon(0px 0px, 100% 0px, 100% 70%, 90% 100%, 0px 100%)",
            height: "40px",
            color: "white",
          }}
        >
          Email
        </Button>
      </div>
      <div className="email-container">
        <div>
          <h6 className="original-email-heading">Original Email:</h6>
          <div className="email">{emailBodyContent}</div>
        </div>
        <div>
          <h6 className="translated-email-heading">Translated Email:</h6>
          <div className="email">{translatedEmail}</div>
        </div>
      </div>

      {/* <h6 className="orignal-email-heading">Orignal Email:</h6>
      <div className="email">{emailBodyContent}</div>
      <h6 className="translated-email-heading">Translated Email:</h6>
      <div className="email">{translatedEmail}</div> */}

      <div className="language-dropdown">
        <label></label>Target Language:
        <Select value={selectedLanguage} onChange={handleChange}>
          <option value="" disabled>
            Select Language
          </option>
          {Object.entries(languages).map(([language, code]) => (
            <option key={code} value={code}>
              {language}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default EmailTranslation;
