import {API_URL, API_KEY, config} from "../../../../config";

export async function translateMail(targetLanguage, content) {
  const _API_KEY=undefined;
  const _API_URL=config.API_BASE_URL;

  const url=`${_API_URL}v2/translate_mail`;
  const body={
    target_language: targetLanguage,
    content: content,
  };

  console.log("fetch -> "+url)
  console.log(body)

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": _API_KEY
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
