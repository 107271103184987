import React from "react";
import { X } from "lucide-react";

const Notification = ({ text, Icon, onClose }) => {
  return (
    <div>
      <div
        style={{
          width: "300px",
          height: "150px",
          // border: "1px solid #8080802b",
          padding: "10px",
          clipPath: "polygon(0px 0px, 100% 0px, 100% 90%, 96% 100%, 0px 100%)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          marginTop: "18px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button onClick={onClose} style={{ background: "none", border: "none", cursor: "pointer" }}>
            <X size={18} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "7px",
          }}
        >
          {Icon && <Icon size={35} />}
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};
export default Notification;
