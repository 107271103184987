import React, { useEffect, useContext } from "react";
import { getWritingStyles } from "../../../../Shared/Utilities/api-helper/writting-styles";
import { TranslationContext } from "../../../..";

const Stylize = ({ setWritingStyle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const language = useContext(TranslationContext);

  const [writingStyles, setWritingStyles] = React.useState({});

  useEffect(() => {
    const fetchWritingStyles = async () => {
      try {
        const lengthResponse = await getWritingStyles(); // Call your API function
        setWritingStyles(lengthResponse); // Update state with the response
        setWritingStyle(Object.values(lengthResponse)[0]);
      } catch (error) {
        console.error("Failed to fetch writing lengths:", error);
      }
    };

    fetchWritingStyles();
  }, []);

  const handleStyleChange = (e) => {
    setWritingStyle(e.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <button
        // key={index}
        shape="circular"
        // onClick={() => onPromptClick(prompt.text)}
        style={{
          borderRadius: "50px",
          fontSize: "12px",
          padding: "8px",
          backgroundColor: "rgb(251 228 217 / 51%)",
          color: "#2E3A48",
          border: "1px solid lightgray",
          margin: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <b>{language.dropdowns.Stylize}:</b>{" "}
        <select
          name="Stylize"
          id="Stylize"
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "rgb(251 228 217 / 11%)",
          }}
          onChange={handleStyleChange}
        >
          {Object.keys(writingStyles).length === 0 ? (
            <option>Loading...</option> // Default option while waiting for data
          ) : (
            Object.keys(writingStyles).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))
          )}
        </select>
        {/* <div>
          <p
            style={{
              margin: 0,
              padding: 0,
              marginLeft: "5px",
            }}
            aria-describedby={id}
            onClick={handleClick}
          >
            Formal <IoIosArrowDown />
          </p>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p>Hello</p>
              <p>Hello</p>
            </div>
          </Popover>
        </div> */}
      </button>
    </div>
  );
};

export default Stylize;
