import React, { useContext } from "react";
import { TranslationContext } from "../../../..";
const SelecteLanguage = ({ setSelectedLanguage, selecteLanguage, languages, getSelectedLanguage }) => {
  const language = useContext(TranslationContext);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    getSelectedLanguage(e.target.value);
  };
  return (
    <div
      style={{
        marginTop: "4px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button
        shape="circular"
        style={{
          borderRadius: "50px",
          width: "90%",
          height: "48px",
          fontSize: "14px",
          padding: "8px",
          backgroundColor: "#dfeef7",
          color: "#2E3A48",
          border: "1px solid lightgray",
          margin: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <b>{language.dropdowns.TargetLanguage}</b>{" "}
        <select
          name="Language"
          id="Language"
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "#dfeef7",
          }}
          value={selecteLanguage}
          onChange={handleLanguageChange} // Pass event to the handler
        >
          <option value="" disabled>
            Select a Language
          </option>
          {Object.entries(languages).map(([languageName, languageCode]) => (
            <option key={languageCode} value={languageCode}>
              {languageName}
            </option>
          ))}
        </select>
      </button>
    </div>
  );
};

export default SelecteLanguage;
