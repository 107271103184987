import React, { useContext } from "react";
import { useState } from "react";
import { TranslationContext } from "../../../..";
import { useNavigate } from "react-router-dom";
import { fetchConversationMessages } from "../../../../Shared/Utilities/api-helper/FetchMessage";
import "./Home.css";
import { Frown } from "lucide-react";
import Notification from "../../Compose/Notification/Notification";
import HomeLogo from "../../../../../../assets/Findus-logo.png";
import MenuBar from "../../Shared/MenuBar/MenuBar";
import Footer from "../../Shared/Footer/Footer";
import Button from "../../Shared/Button/Button";
import { marked } from "marked";
import SummarizeEmail from "../../Shared/ActionBtns/email-summary";
import TranslateEmail from "../../Shared/ActionBtns/translate-email";
import SummarizeAttachment from "../../Shared/ActionBtns/attachment-summary";
import Length from "../../Shared/Dropdowns/length";
import { replyEmail } from "../../../../Shared/Utilities/api-helper/gptService";
import Stylize from "../../Shared/Dropdowns/stylize";
import WorkingBtn from "../../Compose/WorkingBtn/WorkingBtn";
import { writeMail } from "../../../../Shared/Utilities/api-helper/write-mail";
import logo from "../../../assets/Robot-logo.png";
import Prompts from "../../Compose/Prompts/Prompts";
const Home = () => {
  const language = useContext(TranslationContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [writingLength, setWritingLength] = React.useState("short");
  const [writingStyle, setWritingStyle] = React.useState("formal");
  const [emptyPromptNotification, setEmptyPromptNotification] = React.useState(false);

  const handleGenerateDraft = () => {
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const message = await fetchConversationMessages();

      // const reply = await replyEmail(message.plainTextContent);
      const reply = await writeMail(message.content, "", writingStyle, writingLength);

      let parsedContent = marked(reply.answer[0]);

      navigate("/GeneratedDraft", { state: { replyContent: parsedContent } });
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="GenerateMain">
      {isLoading && <WorkingBtn />}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        <MenuBar />
      </div>
      <div className="GeneratedDraftContainer">
        <img src={logo} alt="" width={100} className="image" />

        <div>
          {/* <div className="background-overlay"></div> */}

          <div className="GeneratedDraftContent">
            <h1 className="heading">{language.welcome.title}</h1>
            <p className="subheading">{language.welcome.subTitle}</p>
            <div className="read-style">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TranslateEmail setIsLoading={setIsLoading} setEmptyPromptNotification={setEmptyPromptNotification} />
                <SummarizeEmail setIsLoading={setIsLoading} />
                <SummarizeAttachment />
              </div>
            </div>
            {window.localStorage.getItem("emailType") === "reply" && (
              <>
                {!emptyPromptNotification ? (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        bottom: "93px",
                        left: "45%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      {/* <div>
                        <Prompts />
                      </div> */}
                      <div
                        style={{
                          display: "flex",
                          width: "110%",
                        }}
                      >
                        <Stylize setWritingStyle={setWritingStyle} />
                        <Length setWritingLength={setWritingLength} />
                      </div>
                    </div>
                    <div className="button-container">
                      <Button
                        isLoading={isLoading}
                        onClick={fetchData}
                        loadingText={language.GeneratedDraft.WorkingButton}
                        buttonText={language.welcome.generateButton}
                      />
                    </div>
                  </>
                ) : (
                  <Notification
                    text={language.Notification.emptyPromptNotification}
                    Icon={Frown}
                    onClose={() => {
                      setEmptyPromptNotification(false);
                    }}
                  />
                )}
              </>
            )}

            {window.localStorage.getItem("emailType")==="read" && isLoading && (
              <div className="button-container">
              <Button
                isLoading={isLoading}
                loadingText={language.GeneratedDraft.WorkingButton}
                buttonText={language.welcome.generateButton}
              />
            </div>
            )}

            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
              }}
            >
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
