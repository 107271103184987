import * as React from "react";
import { useState,useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import GeneratedDraft from "./Reply/GeneratedDraft/GeneratedDraft";
import Home from "./Reply/Home";
import Compose from "./Compose/Compose";
import { checkType } from "../../Shared/Utilities/getComposeEmailBody";
import EmailSummary from "./Read/EmailSummary";
import AttachmentSummary from "./Read/AttachmentSummary";
import EmailTranslation from "./Read/EmailTranslation/Home";
const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const Robot = () => {
  const styles = useStyles();
  const [type, setType] = useState(""); // Track the mail type

  // Effect to fetch mail type asynchronously
  useEffect(() => {
    const getMailType = async () => {
      const mailType = await checkType(); // Get mail type asynchronously
      setType(mailType); // Set the mail type in state
      console.log(mailType); // Log for debugging
    };

    getMailType(); // Call the async function
  }, []); // Empty dependency array to run once on mount

  // Conditionally render components based on mail type
  const renderRouteElement = () => {
    if (type === "newMail") {
      window.localStorage.setItem("emailType", "compose");
      return <Compose />;
    } else if (type === "reply") {
      window.localStorage.setItem("emailType", "reply");
      return <Home />;
    } else {
      window.localStorage.setItem("emailType", "read");
      return <Home />;
    }
  };

  return (
    <div className={styles.root}>
      <Router>
        <Routes>
          <Route exact path="/" element={renderRouteElement()} />
          <Route exact path="/Compose" element={<Compose />} />
          <Route exact path="/GeneratedDraft" element={<GeneratedDraft />} />
          <Route exact path="/EmailTranslation" element={<EmailTranslation />} />
          <Route exact path="/AttachmentSummary" element={<AttachmentSummary />} />
          <Route exact path="/EmailSummary" element={<EmailSummary />} />
        </Routes>
      </Router>
    </div>
  );
};

Robot.propTypes = {
  title: PropTypes.string,
};


export default Robot;
