import React, { createContext, useState } from "react";
import { createRoot } from "react-dom/client";
import App from "./Findus/components/App";
import Addeso from "./Addeso/components/App";
import Robot from "./Robot/components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { FindusLanguages } from "./Findus/components/Shared/Tranlation";
import { AddesoLanguages } from "./Addeso/components/Shared/Tranlation";
import { RobotLanguages } from "./Robot/components/Shared/Tranlation";
import {isAdesso , isRobot} from "./config"
/* global document, Office, module, require */

export const TranslationContext = createContext(null);

if (typeof Office !== "undefined" && typeof document !== "undefined") {
  const rootElement = document.getElementById("container");
  if (rootElement) {
    const root = createRoot(rootElement);

    Office.onReady(() => {
      const displayLanguage = Office.context.displayLanguage.split("-")[0];
      const UIText = FindusLanguages[displayLanguage] || FindusLanguages["de"]; // Fallback to English

      root.render(
        <FluentProvider theme={webLightTheme}>
          <TranslationContext.Provider value={UIText}>{getAppComponent()}</TranslationContext.Provider>
        </FluentProvider>
      );
    });

    if (module.hot) {
      module.hot.accept(["./Findus/components/App", "./Addeso/components/App", "./Robot/components/App"], () => {
        root.render(<FluentProvider theme={webLightTheme}>{getAppComponent()}</FluentProvider>);
      });
    }
  }
}

function getAppComponent() {
  if (isAdesso()) return <Addeso />;
  if (isRobot()) return <Robot />;
  return <App />; // Findus
}
