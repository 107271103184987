import {API_URL, API_KEY} from "../../../../config";
export const writeMail = async (content, user_query, writingStyle, writingLength) => {
  return new Promise(async (resolve, reject) => {
    const url=`${API_URL}v2/write_mail`;
    const body={
      content: content,
      user_query: user_query,
      writingOptions: {
        writingStyle: writingStyle,
        writingLength: writingLength,
      },
      number_of_drafts: 1,
    }

    console.log("fetch -> "+url)
    console.log(body)

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": API_KEY
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        // Reject promise with the status text if the response is not ok
        reject(`HTTP error! status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();
      resolve(data); // Resolve the promise with the data
    } catch (error) {
      // Reject the promise with an error
      reject(`Fetch failed: ${error.message}`);
    }
  });
};
