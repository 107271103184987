const env = process.env.NODE_ENV || "development";
const isProduction = env !== "development";

export const config = {
  ROBOT_API_URL: "https://robot.heimat24.de/",
  ROBOT_API_KEY: "21bc8a29-9ce3-4813-b63d-53d46bd7886c",

  ADESSO_API_URL: "https://bot107staging.heimat24.de/apps/outlook-backend-base-staging/api/",
  ADESSO_API_KEY: undefined,

  FINDUS_API_URL: "https://bot107staging.heimat24.de/apps/outlook-backend-base-staging/api/",
  FINDUS_API_KEY: undefined,

  ROBOT_DOMAIN_URL: "https://bot103.heimat24.de/taskpane.html",
  ADESSO_DOMAIN_URL: "https://outlookaddin.heimat24.de/taskpane.html",
  FINDUS_DOMAIN_URL: "https://bot107.heimat24.de/taskpane.html",

  API_BASE_URL: "https://bot107staging.heimat24.de/apps/outlook-backend-base-staging/api/",
};

const ORIGIN=window.location.origin.toLowerCase();
// const ORIGIN="robot";

export function isRobot() {
  console.log(ORIGIN);
  return ORIGIN.includes("robot");
}

export function isAdesso() {
  return ORIGIN.includes("adesso");
}

const getApiUrl = () => {

  if (isAdesso()) {
    return {API_URL: config.ADESSO_API_URL, API_KEY: config.ADESSO_API_KEY};
  } else if (isRobot()) {
    return {API_URL: config.ROBOT_API_URL, API_KEY: config.ROBOT_API_KEY};
  }

  // Default API if no domain matches
  return {API_URL: config.FINDUS_API_URL, API_KEY: config.FINDUS_API_KEY};
};

// Export the selected API URL
export const {API_URL, API_KEY} = getApiUrl();

export default config;
