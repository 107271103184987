import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./GeneratedDraft.css";
import { TranslationContext } from "../../../..";
import { useLocation } from "react-router-dom";
import { marked } from "marked";
import { handleInsertBodyContent } from "../../../../Shared/Utilities/InsertToEmailBody";
import MenuBar from "../../Shared/MenuBar/MenuBar";
import Footer from "../../Shared/Footer/Footer";
import Input from "../../Shared/Input/Input";
import Button from "../../Shared/Button/Button";
import VoiceRecorder from "../../../../Shared/components/VoiceRecorder";
import { SpeechToText } from "../../../../Shared/Utilities/api-helper/SpeechToText";
import Stylize from "../../Shared/Dropdowns/stylize";
import Length from "../../Shared/Dropdowns/length";
import { writeMail } from "../../../../Shared/Utilities/api-helper/write-mail";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const GeneratedDraft = () => {
  const language = useContext(TranslationContext);
  const location = useLocation();
  const [replyContent, setReplyContent] = useState(location.state?.replyContent);
  const [isLoading, setIsLoading] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [writingLength, setWritingLength] = useState("short");
  const [writingStyle, setWritingStyle] = useState("casual");
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const handleRewrite = () => {
    handleRewriteEmail(instruction);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action (like form submission)
      handleRewrite(); // Call the send function
    }
  };
  const handleRewriteEmail = async (instruction) => {
    setIsLoading(true);
    try {
      //  const res = await rewriteDraftedReply(replyContent, instruction);
      const res = await writeMail(replyContent, instruction, writingStyle, writingLength);

      // let parsedContent = marked.parse(res.answer).toString();

      let replResponse = marked(res.answer[0]);

      setReplyContent(replResponse);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInsertReply = () => {
    handleInsertBodyContent(replyContent);
  };

  // Function to handle the transcription from VoiceRecorder
  const handleTranscription = (VoiceInput) => {
    handleSpeechToText(VoiceInput);
  };

  const handleSpeechToText = (VoiceInput) => {
    // Ensure that VoiceInput is a valid Blob or File
    console.log(VoiceInput);
    setIsLoading(true);
    // Call the SpeechToText function with the provided VoiceInput
    SpeechToText(VoiceInput)
      .then((res) => {
        // Handle successful response
        console.log("Speech-to-Text Result:", res);
        setInstruction(res.text);
        handleRewriteEmail(res.text);
        // You can process the response further here, such as updating the UI
      })
      .catch((error) => {
        // Handle any errors that occurred during the conversion
        setIsLoading(false);
        console.error("Error during speech-to-text conversion:", error);
      });
  };
  return (
    <div>
      <div
        style={{
          overflowY: "auto",
          padding: "24px",
          fontSize: "13px",
          paddingBottom: "-20px",
          height: "calc(100vh - 220px)",
          borderBottom: "1px solid black",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MdOutlineKeyboardBackspace
            fontSize={20}
            onClick={goBack}
            style={{
              margin: "10px",
              cursor: "pointer",
            }}
          />{" "}
          {language.GeneratedDraft.Response}
          <MenuBar />
        </p>

        <p dangerouslySetInnerHTML={{ __html: replyContent }} />
      </div>

      <div style={{ display: "flex", gap: "-8px", justifyContent: "center" }}>
        <Stylize setWritingStyle={setWritingStyle} />
        <Length setWritingLength={setWritingLength} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <VoiceRecorder setIsLoading={setIsLoading} handleTranscription={handleTranscription} />
        <Input
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          onSubmit={handleRewrite}
          onKeyDown={handleKeyDown}
          placeholder={language.GeneratedDraft.Placeholder}
          isLoading={isLoading}
          buttonStyle={{
            backgroundColor: "#2E3A48",
            color: "#00e7fe",
            padding: "14px",
          }}
          containerStyle={{
            display: "flex",
            width: "83%",
            height: "50px",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
            background: "#ffffff",
            borderRadius: "50px",
            border: "1px solid #80808052",
            margin: "auto",
          }}
        />
      </div>
      <Button
        isLoading={isLoading}
        onClick={onInsertReply}
        loadingText={language.GeneratedDraft.WorkingButton}
        buttonText={language.GeneratedDraft.InsertButton}
      />
      <p
        style={{
          position: "fixed",
          bottom: "-20px",
          fontSize: "11px",
          right: "50px",
        }}
      >
        <Footer />
      </p>
    </div>
  );
};

export default GeneratedDraft;
