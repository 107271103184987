import {API_URL, API_KEY, config} from "../../../../config";

export async function analyzeConversationHistory(historyData) {
  const _API_KEY=config.ROBOT_API_KEY;
  const _API_URL=config.ROBOT_API_URL;

  const url=`${_API_URL}v2/analyze_history`;
  const body=historyData;

  console.log("fetch -> "+url)
  console.log(body)

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": _API_KEY
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
