import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbFileTypeDocx } from "react-icons/tb";
import { getAllAttachments, getAttachmentContent } from "../../../../Shared/Utilities/api-helper/CurrentEmail";
import Loader from "../../../../Shared/components/loader";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "../EmailTranslation/Home/index.css";
const AttachmentSummary = () => {
  const [attachments, setAttachments] = useState([]);
  const [attachmentsContent, setAttachmentsContent] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/EmailTranslation");
  };

  // Fetch all attachments on component mount
  useEffect(() => {
    getAllAttachments().then((res) => {
      console.log(res);
      setAttachments(res);
    });
  }, []);

  // Handle accordion state and API call for fetching content
  const handleAccordionChange = (attachment) => (event, isExpanded) => {
    if (isExpanded && !attachmentsContent[attachment.name]) {
      // Fetch content only if the accordion is expanded and content is not already loaded
      setLoader(true);
      getAttachmentContent(attachment).then((res) => {
        console.log(res);
        setAttachmentsContent((prev) => ({
          ...prev,
          [attachment.name]: res.answer[0], // Store content by attachment name
        }));
        setLoader(false);
      });
    }
  };

  return (
    <div>
      {loader && <Loader />}

      <h3 style={{ padding: "15px" }}>
        {" "}
        <MdOutlineKeyboardBackspace
          fontSize={25}
          onClick={goBack}
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
        />
        Attachment Summary
      </h3>
      <div>
        {attachments.map((attachment, index) => (
          <Accordion
            key={index}
            onChange={!loader && handleAccordionChange(attachment)} // Use the handler
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <TbFileTypeDocx fontSize={20} style={{ paddingRight: "7px" }} />
              {attachment.name}
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              {attachmentsContent[attachment.name] || "Wait a moment to load the attachment summary"}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default AttachmentSummary;
