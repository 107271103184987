import React, { useState, useContext } from "react";
import "./GeneratedDraft.css";
import { TranslationContext } from "../../../..";
import { useLocation } from "react-router-dom";
import { rewriteDraftedReply } from "../../../../Shared/Utilities/api-helper/Rewrite";
import { handleInsertBodyContent } from "../../../../Shared/Utilities/InsertToEmailBody";
import { InfoRegular } from "@fluentui/react-icons";
import MenuBar from "../../Shared/MenuBar/MenuBar";
import Footer from "../../Shared/Footer/Footer";
import Input from "../../Shared/Input/Input";
import Button from "../../Shared/Button/Button";
import VoiceRecorder from "../../../../Shared/components/VoiceRecorder";
import { SpeechToText } from "../../../../Shared/Utilities/api-helper/SpeechToText";
import { marked } from "marked";

const GeneratedDraft = () => {
  const language = useContext(TranslationContext);
  const location = useLocation();
  const [replyContent, setReplyContent] = useState(location.state?.replyContent);
  const [isLoading, setIsLoading] = useState(false);
  const [instruction, setInstruction] = useState("");

  const handleRewrite = () => {
    handleRewriteEmail(instruction);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action (like form submission)
      handleRewrite(); // Call the send function
    }
  };
  const handleRewriteEmail = async (instruction) => {
    setIsLoading(true);
    try {
      const res = await rewriteDraftedReply(replyContent, instruction);
      //  let parsedContent = marked.parse(res.answer).toString();

      let replResponse = res.answer;
      if (res.answer.includes("`")) {
        replResponse = res.answer.replaceAll("`", "");
      }
      setReplyContent(replResponse);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInsertReply = () => {
    handleInsertBodyContent(replyContent);
  };

  const handleTranscription = (VoiceInput) => {
    handleSpeechToText(VoiceInput);
  };

  const handleSpeechToText = (VoiceInput) => {
    // Ensure that VoiceInput is a valid Blob or File
    console.log(VoiceInput);
    setIsLoading(true);
    // Call the SpeechToText function with the provided VoiceInput
    SpeechToText(VoiceInput)
      .then((res) => {
        // Handle successful response
        console.log("Speech-to-Text Result:", res);
        setInstruction(res.text);
        handleRewriteEmail(res.text);
        // You can process the response further here, such as updating the UI
      })
      .catch((error) => {
        // Handle any errors that occurred during the conversion
        setIsLoading(false);
        console.error("Error during speech-to-text conversion:", error);
      });
  };
  return (
    <div>
      <div
        style={{
          overflowY: "auto",
          padding: "24px",
          fontSize: "13px",
          height: "calc(100vh - 185px)",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {language.GeneratedDraft.Response} <InfoRegular fontSize={18} />
          <MenuBar />
        </p>

        <p dangerouslySetInnerHTML={{ __html: replyContent }} />
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <VoiceRecorder setIsLoading={setIsLoading} handleTranscription={handleTranscription} />
        <Input
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          onSubmit={handleRewrite}
          onKeyDown={handleKeyDown}
          placeholder={language.GeneratedDraft.Placeholder}
          isLoading={isLoading}
          buttonStyle={{
            color: "rgba(27, 110, 195, 1)",
            padding: "14px",
          }}
          containerStyle={{
            display: "flex",
            width: "83%",
            height: "50px",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
            background: "#ffffff",
            border: "1px solid #80808052",
            margin: "auto",
          }}
        />
      </div>

      <Button
        isLoading={isLoading}
        onClick={onInsertReply}
        loadingText={language.GeneratedDraft.WorkingButton}
        buttonText={language.GeneratedDraft.InsertButton}
      />
      <p
        style={{
          position: "fixed",
          bottom: "-20px",
          fontSize: "11px",
          right: "50px",
        }}
      >
        <Footer />
      </p>
    </div>
  );
};

export default GeneratedDraft;
