import React, { useContext, useState, useEffect } from "react";
import { TranslationContext } from "../../../..";
import { useNavigate } from "react-router-dom";
import { fetchConversationMessages } from "../../../../Shared/Utilities/api-helper/FetchMessage";
import "./Home.css";
import MenuBar from "../../Shared/MenuBar/MenuBar";
import Footer from "../../Shared/Footer/Footer";
import Button from "../../Shared/Button/Button";
import { marked } from "marked";
import AddesoLogo from "../../../assets/Addeso-logo.png";
import { translateMail } from "../../../../Shared/Utilities/api-helper/translate-email";
import { getEmailBody } from "../../../../Shared/Utilities/api-helper/CurrentEmail";

const Home = () => {
  const language = useContext(TranslationContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handlefetchdataAndNavigation = async () => {
    try {
      if (!Office.context.mailbox.item.itemType.displayReplyForm) {
        // Navigate to EmailTranslation

        setIsLoading(true); // Set loading to true before the async operation starts

        try {
          let emailBodyContent = await getEmailBody();

          const response = await translateMail("language", emailBodyContent);
          console.log(response); // Log the translation result
          navigate("/EmailTranslation", {
            state: { translatedContent: response.answer[0], emailContent: emailBodyContent },
          });
        } catch (error) {
          console.error("Error translating email:", error); // Log the error
        } finally {
          setIsLoading(false); // Ensure loading is false after the async operation, regardless of success or failure
        }
      } else {
        setIsLoading(true);
        const reply = await fetchConversationMessages();
        const parsedContent = marked.parse(reply.reply.answer).toString();
        navigate("/GeneratedDraft", { state: { replyContent: parsedContent } });
      }
    } catch (error) {
      console.error("Error handling button click:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        <MenuBar />
      </div>
      <div className="GenContainer">
        <img src={AddesoLogo} alt="Addeso Logo" width={160} className="image" />
        <div>
          <div className="GenContent">
            <h1
              onClick={() => navigate("/EmailTranslation")}
              style={{
                color: "#C8B630",
                fontSize: "22px",
                fontWeight: "700",
                fontFamily: "Montserrat",
                cursor: "pointer",
              }}
            >
              {language.welcome.title}
            </h1>
            <p
              style={{
                color: "#2E3A48",
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "13px",
              }}
            >
              {language.welcome.subTitle}
            </p>
            <div className="button-container">
              <Button
                isLoading={isLoading}
                onClick={handlefetchdataAndNavigation}
                loadingText={language.GeneratedDraft.WorkingButton}
                buttonText={
                  !Office.context.mailbox.item.itemType.displayReplyForm
                    ? "Translate Email"
                    : language.welcome.generateButton
                }
              />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
