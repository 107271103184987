import React, { useContext, useState } from "react";
import { PiTranslateLight } from "react-icons/pi";
import "./actions.css";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

import { getEmailBody } from "../../../../Shared/Utilities/api-helper/CurrentEmail";
import { translateMail } from "../../../../Shared/Utilities/api-helper/translate-email";
import { marked } from "marked";
const TranslateEmail = ({ setIsLoading, setEmptyPromptNotification }) => {
  const navigate = useNavigate();
  let myLanguage = Office.context.displayLanguage.split("-")[0];
  const handleEmailTranslation = async () => {
    setIsLoading(true);

    try {
      let emailBodyContent = await getEmailBody();
      if (emailBodyContent.trim() !== "") {
        setEmptyPromptNotification(false);

        const response = await translateMail(myLanguage, emailBodyContent);
        console.log(response); // Log the translation result
        let markedDownContent = marked(response.answer[0]);
        navigate("/EmailTranslation", {
          state: {
            translatedContent: response.answer[0],
            emailContent: emailBodyContent,
            translatedMarkedDownEmail: markedDownContent,
            myLanguage: myLanguage
          },
        });
      } else {
        setEmptyPromptNotification(true);
      }
      // navigate("/EmailTranslation");
    } catch (error) {
      console.error("Error translating email:", error); // Log the error
    } finally {
      setIsLoading(false); // Ensure loading is false after the async operation, regardless of success or failure
    }
  };

  return (
    <div>
      <Tooltip title="Translate Email" arrow placement="top" className="tooltip-style">
        <div className="action-btn">
          <PiTranslateLight fontSize={25} strokeWidth={1.2} onClick={handleEmailTranslation} />
        </div>
      </Tooltip>
    </div>
  );
};

export default TranslateEmail;
