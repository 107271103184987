import { API_URL } from "../../../../config";
export async function rewriteDraftedReply(content, insturction) {
  return new Promise((resolve, reject) => {
    console.log("using old endpoint -> "+`${API_URL}rewrite/`)

    fetch(`${API_URL}rewrite/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: content, instruction: insturction }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
