import React, { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../..";
import "./Compose.css";
import { Frown, MessageSquare } from "lucide-react";
import Prompts from "./Prompts/Prompts";
import Notification from "./Notification/Notification";
import MenuBar from "../Shared/MenuBar/MenuBar";
import { getBodyAsync } from "../../../Shared/Utilities/getComposeEmailBody";
import { writeMail } from "../../../Shared/Utilities/api-helper/write-mail";
import { handleInsertBodyContent } from "../../../Shared/Utilities/InsertToEmailBody";
import Footer from "../Shared/Footer/Footer";
import Input from "../Shared/Input/Input";
import WorkingBtn from "./WorkingBtn/WorkingBtn";
import VoiceRecorder from "../../../Shared/components/VoiceRecorder";
import { SpeechToText } from "../../../Shared/Utilities/api-helper/SpeechToText";
import logo from "../../assets/Robot-logo.png";
import TranslateEmail from "../Shared/ActionBtns/translate-email";
import Length from "../Shared/Dropdowns/length";
import Stylize from "../Shared/Dropdowns/stylize";
import { marked } from "marked";
import SummarizeAttachment from "../Shared/ActionBtns/attachment-summary";
const Compose = () => {
  const language = useContext(TranslationContext);
  const [emptyPromptNotification, setEmptyPromptNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [irrelevantPromptNotification, setIrrelevantPromptNotification] = useState(true);
  const [instruction, setInstruction] = useState("");
  const [writingLength, setWritingLength] = useState("medium");
  const [writingStyle, setWritingStyle] = useState("formal");

  useEffect(() => {
    if (irrelevantPromptNotification) {
      setTimeout(() => {
        setIrrelevantPromptNotification(false);
      }, 3000);
    }
  }, [irrelevantPromptNotification]);

  const handlePromptClick = async (text) => {
    setIsLoading(true);

    try {
      const body = await getBodyAsync();

      if (body.trim() === "") {
        setEmptyPromptNotification(true);
        return;
      }

      setEmptyPromptNotification(false);

      const spellCheck=language.prompts.SpellCheck
      if (text.includes(spellCheck)) {
        const improvedGrammar = await improveGrammar(body);
        onInsert(marked(improvedGrammar.answer[0]));
      } else {
        handleRewriteEmail(text);
      }
    } catch (error) {
      console.error("Error handling prompt click:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = async () => {
    // const body = await getBodyAsync();
    // if (body.trim() !== "") {
    // setEmptyPromptNotification(false);
    handleRewriteEmail(instruction);
    // } else {
    //   setEmptyPromptNotification(true);
    // }
  };
  const handleRewrite = () => {
    handleRewriteEmail();
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      //const body = await getBodyAsync();
      // if (body.trim() !== "") {
      //setEmptyPromptNotification(false);
      handleRewriteEmail(instruction);
      // } else {
      //   setEmptyPromptNotification(true);
      // }
    }
  };
  const handleRewriteEmail = async (instruction) => {
    if(instruction.trim() == "") {
      setEmptyPromptNotification(true);
      return;
    } else {
      setEmptyPromptNotification(false);
    }


    setIsLoading(true);
    try {
      const body = await getBodyAsync();

      //const res = await rewriteDraftedReply(body, instruction, wrtingStyle, wrtingLength);
      const res = await writeMail(body, instruction, writingStyle, writingLength);

      onInsert(marked(res.answer[0]));

      setIsLoading(false);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInsert = (content) => {
    handleInsertBodyContent(content);
  };
  // New function to handle voice transcription
  const handleTranscription = (VoiceInput) => {
    handleSpeechToText(VoiceInput);
  };

  const handleSpeechToText = (VoiceInput) => {
    // Ensure that VoiceInput is a valid Blob or File
    console.log(VoiceInput);
    setIsLoading(true);
    // Call the SpeechToText function with the provided VoiceInput
    SpeechToText(VoiceInput)
      .then((res) => {
        // Handle successful response
        console.log("Speech-to-Text Result:", res);
        setInstruction(res.text);
        setIsLoading(false);

        handleSend();
        // You can process the response further here, such as updating the UI
      })
      .catch((error) => {
        // Handle any errors that occurred during the conversion
        setIsLoading(false);
        console.error("Error during speech-to-text conversion:", error);
      });
  };
  return (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        <MenuBar />
      </div>
      <div
        className="composeContainer"
        style={{
          paddingTop: irrelevantPromptNotification ? "0px" : emptyPromptNotification ? "5px" : "90px",
        }}
      >
        <img src={logo} alt="" width={100} className="image" />
        <div>
          <div className="content">
            <h1 className="heading">{language.welcome.title}</h1>
            <p className="subheading">{language.welcome.subTitle}</p>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!irrelevantPromptNotification && !emptyPromptNotification && (
                <div
                  style={{
                    gap: "15px",
                    display: "flex",
                  }}
                >
                  <TranslateEmail setIsLoading={setIsLoading} setEmptyPromptNotification={setEmptyPromptNotification} />
                  <SummarizeAttachment />
                </div>
              )}
            </div>
            <div className="button-container">
              <>
                <div
                  style={{
                    position: "fixed",
                    bottom: irrelevantPromptNotification || emptyPromptNotification ? "250px" : "90px",
                  }}
                >
                  <Prompts
                    onPromptClick={handlePromptClick}
                    isIrrelevant={irrelevantPromptNotification}
                    isRelevant={emptyPromptNotification}
                    isLoading={isLoading}
                  />

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Length setWritingLength={setWritingLength} />
                    <Stylize setWritingStyle={setWritingStyle} />
                  </div>
                </div>
                {isLoading ? (
                  <WorkingBtn />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <VoiceRecorder top="-20%" setIsLoading={setIsLoading} handleTranscription={handleTranscription} />
                    </div>
                    {isLoading ? (
                      <WorkingBtn />
                    ) : (
                      <Input
                        value={instruction}
                        onChange={(e) => setInstruction(e.target.value)}
                        onSubmit={handleSend}
                        onKeyDown={handleKeyDown}
                        placeholder={language.promptInput.InputPlaceholder}
                        isLoading={isLoading}
                        buttonStyle={{
                          backgroundColor: "rgba(46, 58, 72, 1)",
                          color: "white",
                          padding: "14px",
                        }}
                        containerStyle={{
                          display: "flex",
                          width: "83%",
                          height: "50px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "8px",
                          background: "#ffffff",
                          borderRadius: "50px",
                          border: "1px solid #80808052",
                          position: irrelevantPromptNotification ? "static" : "fixed",
                          bottom: emptyPromptNotification ? "207px" : "45px",
                        }}
                      />
                    )}
                  </div>
                )}
              </>

              {(emptyPromptNotification || irrelevantPromptNotification) && (
                <Notification
                  text={
                    emptyPromptNotification
                      ? language.Notification.emptyPromptNotification
                      : language.Notification.irrelevantPromptNotification
                  }
                  Icon={emptyPromptNotification ? Frown : MessageSquare}
                  onClose={() => {
                    setIrrelevantPromptNotification(false);
                    setEmptyPromptNotification(false);
                  }}
                />
              )}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compose;
