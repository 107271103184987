import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import { Tooltip } from "@mui/material";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa6";
import { LuClipboardPaste } from "react-icons/lu";
import WorkingBtn from "../../../Compose/WorkingBtn/WorkingBtn";
import Footer from "../../../Shared/Footer/Footer";
import { fetchLanguages } from "../../../../../Shared/Utilities/api-helper/languages";
import { translateMail } from "../../../../../Shared/Utilities/api-helper/translate-email";
import { TranslationContext } from "../../../../..";
import { useNavigate, useLocation } from "react-router-dom";
import SelecteLanguage from "../../../Shared/Dropdowns/language-selection";
import { Button } from "@fluentui/react-components";
import { handleInsertBodyContent } from "../../../../../Shared/Utilities/InsertToEmailBody";
import { copyToClipboard } from "../../../../../Shared/Utilities/copy-content";
import { marked } from "marked";


const EmailTranslation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const language = useContext(TranslationContext);
  const [languages, setLanguages] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(location.state?.myLanguage);
  const [translatedEmail, setTranslatedEmail] = useState(location.state?.translatedContent || "");
  const [translatedMarkedDownEmail, setTranslatedMarkedDownEmail] = useState(
    location.state?.translatedMarkedDownEmail || ""
  );

  const [emailBodyContent, setEmailBodyContent] = useState(location.state?.emailContent || "");

  // Fetch languages on component mount
  useEffect(() => {
    fetchLanguages().then((res) => {
      console.log("Fetched languages:", res);
      setLanguages(res); // Save the fetched language object
    });
  }, []);

  // Handle language selection and translation
  const handleChange = async (value) => {
    //const selectedLanguageValue = value;
    // setSelectedLanguage(selectedLanguageValue);
    // console.log("Selected language:", selectedLanguageValue);
    setLoading(true); // Show loader while translating
    try {
      const response = await translateMail(value, emailBodyContent);
      console.log("Translation response:", response);
      setTranslatedMarkedDownEmail(marked(response.answer[0]));
      setTranslatedEmail(response.answer[0]);
    } catch (error) {
      console.error("Error translating email:", error);
    } finally {
      setLoading(false); // Hide loader after translation
    }
  };

  const goHome = () => navigate("/");
  const habdleInsert = () => handleInsertBodyContent(translatedMarkedDownEmail);
  const handleCopy = () => copyToClipboard(translatedEmail);

  return (
    <div>
      <div className="translate-heading">
        <MdOutlineKeyboardBackspace fontSize={25} onClick={goHome} style={{ margin: "7px", cursor: "pointer" }} />
        <h5>{language.translateLetter.translateLetter}</h5>
      </div>



      <h6 className="orignal-email-heading">{language.translateLetter.OriginalEmail}</h6>
      <div className="RobotEmail">{emailBodyContent}</div>
      <hr />
      <h6 className="translated-email-heading">{language.translateLetter.TranslatedEmail}</h6>
      <div className="RobotEmail" dangerouslySetInnerHTML={{ __html: translatedEmail }} />
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "27px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "15px",
            paddingRight: "15px",
          }}
        >
          <Tooltip title="Copy" arrow placement="top">
            <Button icon={<FaRegCopy fontSize={21} onClick={handleCopy} />} />
          </Tooltip>
          <Tooltip title="Insert" arrow placement="top">
            <Button icon={<LuClipboardPaste fontSize={23} onClick={habdleInsert} />} />
          </Tooltip>
        </div>
        {loading ? (
          <WorkingBtn />
        ) : (
          <SelecteLanguage
            setSelectedLanguage={setSelectedLanguage}
            selecteLanguage={selectedLanguage}
            languages={languages}
            getSelectedLanguage={handleChange}
          />
        )}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "-8px",
          left: "46px",
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export default EmailTranslation;
