import {API_URL, API_KEY, config} from "../../../../config";
export function improveGrammar(content) {
  const _API_KEY=config.ROBOT_API_KEY;
  const _API_URL=config.ROBOT_API_URL;

  const url=`${_API_URL}v2/improve_grammer`;
  const body={ content };

  console.log("fetch -> "+url)
  console.log(body)

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": _API_KEY
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          return reject(new Error("Network response was not ok"));
        }
        return response.json();
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
