import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import GeneratedDraft from "./Reply/GeneratedDraft/GeneratedDraft";
import Home from "./Reply/Home";
import Compose from "./Compose/Compose";
import EmailTranslation from "./Read/EmailTranslation/Home";
import AttachmentSummary from "./Read/AttachmentSummary";
import EmailSummary from "./Read/EmailSummary";
const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const Addeso = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              Office.context.mailbox.item.itemType.displayReplyForm ? (
                <Home />
              ) : Office.context.mailbox.item.conversationId == null ||
                Office.context.mailbox.item.conversationId === "" ? (
                <Compose />
              ) : (
                <Home />
              )
            }
          />
          <Route exact path="/Compose" element={<Compose />} />
          <Route exact path="/GeneratedDraft" element={<GeneratedDraft />} />
          <Route exact path="/EmailTranslation" element={<EmailTranslation />} />
          <Route exact path="/AttachmentSummary" element={<AttachmentSummary />} />
          <Route exact path="/EmailSummary" element={<EmailSummary />} />


        </Routes>
      </Router>
    </div>
  );
};

Addeso.propTypes = {
  title: PropTypes.string,
};

export default Addeso;
