import {API_URL, API_KEY, config} from "../../../../config";

export async function analyzeAttachment(file) {
  return new Promise((resolve, reject) => {
    const _API_KEY=config.ROBOT_API_KEY;
    const _API_URL=config.ROBOT_API_URL;

    const formData = new FormData();
    formData.append("file", file);

    const url=`${_API_URL}v2/analyze_attachment`
    console.log("fetch -> "+url);

    fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "X-API-Key": _API_KEY
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
